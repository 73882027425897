import format from 'date-fns/format/index.js';
import { $addClass, $delegate, $find, $qs, $qsa } from 'fxdom/es';

import { each, go } from 'fxjs/es';
import 'intersection-observer';
import querystring from 'querystring';
import { resetUserCounts } from '../../../../../../modules/MPay/Cart/F/fs.js';
import { UserJwtF } from '../../../../../../modules/User/Jwt/F/Function/module/UserJwtF.js';
import { UtilF } from '../../../../../../modules/Util/F/Function/module/UtilF.js';

import './webview.js';
import { UtilImageS } from '../../../../../../modules/Util/Image/S/Function/module/UtilImageS.js';
import { findDropdownEl } from '../../../../../../modules/MShop/Core/F/Function/dropdown.js';
import { MShopCoreF } from '../../../../../../modules/MShop/Core/F/Function/module/MShopCoreF.js';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS.js';

const checkDifferentUser = (bu, u) => bu.id && bu.id == u.id && bu.type == u.type;

async function afterSessionCheck(user) {
  if (!user) return;
  if (checkDifferentUser(box.sel('is_user'), user)) return resetUserCounts();
  await UserJwtF.loginMessage(user);
  return window.location.reload();
}

function checkParse(response) {
  try {
    return JSON.parse(response);
  } catch (e) {
    return 'Parse Error';
  }
}

window.isSessionCheckNow = function () {
  const xhttp = new XMLHttpRequest();
  xhttp.open('GET', '/@api/mp/is_session', true);
  xhttp.send();
  xhttp.onreadystatechange = function recur() {
    if (!_p.box) return setTimeout(recur, 10);
    if (!window.box) {
      window.box = _p.box(ENV.box);
      delete ENV.box;
    }
    if (this.readyState == 4 && this.status == 200) {
      const html = document.getElementsByTagName('html')[0];
      const user = checkParse(this.response);
      if (user == 'Parse Error') return;
      const is_session = !!user;
      if (window.box && user && window.box.sel('is_user->id') && user.id !== window.box.sel('is_user->id'))
        return location.reload();
      if (!is_session && html.getAttribute('is_session') == 'true') return location.reload();
      if (is_session && user) return afterSessionCheck(user);
    }
  };
};

UtilF.initBackForward(isSessionCheckNow);

const appListener = async function (e) {
  try {
    const { is_focused } = JSON.parse(e.data);
    if (is_focused) await resetUserCounts();
  } catch (err) {}
};
window.addEventListener('message', appListener);
document.addEventListener('message', appListener);

// window.addEventListener('beforeunload', function (e) {
//   // document.querySelectorAll('video').forEach(function(a) { a.style.display='none'});
//   const video_el = document.querySelectorAll('video');
//   for (let i = 0; i < video_el.length; i++) {
//     video_el[i].style.display = 'none';
//   }
// });
//
// window.ff = format;
window.addEventListener(
  'error',
  function (e) {
    if (e.target.tagName?.toLowerCase() !== 'img' || e.target.before_src) return;
    const [u, q] = e.target.src.split('?');
    e.target.before_src = e.target.src;
    e.target.src =
      u + '?' + querystring.stringify({ ...querystring.parse(q), __d: format(Date.now(), 'yyyyMMdd') });
  },
  true,
);

const html = document.getElementsByTagName('html')[0];
html.setAttribute('is_mobile_size', html.offsetWidth < 1025);

const img = new Image();
img.onerror = (e) => {
  (function recur() {
    each((img) => {
      if (/\.webp|f=webp/gi.test($addClass('webppp', img).src)) {
        img.src = UtilImageS.updateResizeOption({
          url: img.src,
          format: 'png',
        });
      }
    }, $qsa('img:not(.webppp)'));
    each((el) => {
      if (/\.webp|f=webp/gi.test($addClass('webppp', el).style.backgroundImage)) {
        el.style.backgroundImage = `url(${UtilImageS.updateResizeOption({
          url: el.style.backgroundImage.slice(4, -1).replace(/"/g, ''),
          format: 'png',
        })})`;
      }
    }, $qsa('[style]:not(.webppp)'));
    setTimeout(recur, 1000);
  })();
};
img.src =
  'https://image1.marpple.co/files/u_1485274/2021/10/original/84e685b1b47bde000e9b9effcae22872f22082d31.png?f=webp&w=1&q=1';

// footer lang 부분 프론트에서 핸들링
{
  const component = 'mshop-default-footer';
  const footer_el = $qs(`.${component}`);
  if (footer_el) {
    const company_detail_el = $find(`.${component}__company-detail`, footer_el);

    // pc 에서 lang 부분 드랍다운
    const dropdown_el = findDropdownEl(footer_el);
    if (dropdown_el) {
      MShopCoreF.initDropdown({
        target_el: dropdown_el,
        hooks: {
          onChange: function onChangeFooterLang(e, { value }) {
            location.href = UtilS.changeUrlLang(location.href, value);
          },
        },
      });
    }

    go(
      footer_el,
      $delegate('click', `.${component}__lang`, function onChangeLang(e) {
        e.originalEvent.preventDefault();
        const { lang } = e.currentTarget.dataset;
        location.href = UtilS.changeUrlLang(location.href, lang);
      }),
      $delegate('click', `.${component}__company`, function onOpenDetail(e) {
        const company_el = e.currentTarget;
        const is_open = company_el.dataset.open == 'true';
        company_el.dataset.open = (!is_open).toString();
        company_detail_el.dataset.open = (!is_open).toString();
      }),
    );
  }
}
