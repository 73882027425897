import axios from 'axios';
import { $attr, $delegate } from 'fxdom/es';
import $parseDataStr from 'fxdom/es/parseDataStr.js';
import { delay, go } from 'fxjs/es';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';

if (MShopUtilF.isApp()) {
  go(
    document.body,
    $delegate('click', '[data-post-message]', (e) => {
      e.originalEvent.preventDefault();
      MShopUtilF.postMessage($parseDataStr(e.currentTarget.dataset.postMessage));
    }),
    $delegate('click', 'a[href][data-new-tab="true"]', (e) => {
      e.originalEvent.preventDefault();
      MShopUtilF.postMessage({
        screen: {
          type: 'empty',
          uri: e.currentTarget.href,
        },
      });
    }),
    $delegate('click', 'a.mp-shop-lang', (e) => {
      e.originalEvent.preventDefault();
      $.don_loader_start();
      MShopUtilF.postMessage({ locale: $attr('lang', e.currentTarget) });
    }),
  );
}
